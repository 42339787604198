<template>
  <q-page>
    <VehicleDefectsTable />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import VehicleDefectsTable from '@/components/tools/vehicle-defects/VehicleDefectsTable.vue'

export default defineComponent({
  name: 'VehicleDefects',

  components: {
    VehicleDefectsTable,
  },

  setup() {
    return {}
  },
})
</script>
