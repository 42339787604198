
import { defineComponent, ref } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import { useProfile } from '@/composable/useProfile'
import { exportExcel } from '@/services/export-excel'
import { useVehicleDefect } from '@/composable/useVehicleDefect'
import { format } from 'date-fns'
import { VehicleDefect } from '@/types/vehicle-defect'

export default defineComponent({
  name: 'VehicleDefectsTable',

  setup() {
    const { fetchAll, data: vehicleDefects, loading } = useVehicleDefect()
    const { can } = useProfile()
    const exporting = ref(false)
    fetchAll()

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'class',
        label: 'Littera',
        field: 'class',
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicleNumber',
        label: 'Fordonsnummer',
        field: 'vehicleNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicleUniqueId',
        label: 'Fiktivt fordonsnummer',
        field: 'vehicleUniqueId',
        sortable: true,
      },

      {
        align: 'left',
        name: 'consequenceCode',
        label: 'Konsevenskod',
        field: 'consequenceCode',
        sortable: true,
      },
      {
        align: 'left',
        name: 'creationDateAndTime',
        label: 'Inskrivet',
        field: 'creationDateAndTime',
        format: (x: string) => format(new Date(x), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectCode',
        label: 'Skadekod',
        field: 'defectCode',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectDateAndTime',
        label: 'Skadetidpunkt',
        field: 'defectDateAndTime',
        format: (x: string) => format(new Date(x), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectDescription',
        label: 'Skadebeskrivning',
        field: 'defectDescription',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectHeading',
        label: 'Rubriktext',
        field: 'defectHeading',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectNumber',
        label: 'Skadelöpnummer',
        field: 'defectNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectRegistrationDepot',
        label: 'Rapporterande plats',
        field: 'defectRegistrationDepot',
        sortable: true,
      },
      {
        align: 'left',
        name: 'delayMinutes',
        label: 'Försening (min.)',
        field: 'delayMinutes',
        sortable: true,
      },
      {
        align: 'left',
        name: 'equipmentCheck',
        label: 'Beredskapskontroll',
        field: 'equipmentCheck',
        sortable: true,
      },
      {
        align: 'left',
        name: 'canceledReason',
        label: 'Information klass',
        field: 'canceledReason',
        sortable: true,
      },
      {
        align: 'left',
        name: 'informationSource',
        label: 'Uppgiftslämnare',
        field: 'informationSource',
        sortable: true,
      },
      {
        align: 'left',
        name: 'lastUpdatedDateAndTime',
        label: 'Senast uppdaterad',
        field: 'lastUpdatedDateAndTime',
        format: (x: string | null) =>
          x ? format(new Date(x), 'yyyy-MM-dd HH:mm') : '',
        sortable: true,
      },
      {
        align: 'left',
        name: 'maintenanceReferenceId',
        label: 'UH Ext. Skadenr.',
        field: 'maintenanceReferenceId',
        sortable: true,
      },
      {
        align: 'left',
        name: 'outOfService',
        label: 'Ur funktion',
        field: 'outOfService',
        sortable: true,
      },
      {
        align: 'left',
        name: 'positionInTrain',
        label: 'Ordningsnummer i tågsätt',
        field: 'positionInTrain',
        sortable: true,
      },
      {
        align: 'left',
        name: 'positionInUnit',
        label: 'Vagn',
        field: 'positionInUnit',
        sortable: true,
      },
      {
        align: 'left',
        name: 'priority',
        label: 'Prioritet',
        field: 'priority',
        sortable: true,
      },
      {
        align: 'left',
        name: 'reportedBy',
        label: 'Rapportör',
        field: 'reportedBy',
        sortable: true,
      },
      {
        align: 'left',
        name: 'splitTable',
        label: 'Underhållstabell',
        field: 'splitTable',
        sortable: true,
      },
      {
        align: 'left',
        name: 'totalPerformanceQuantity',
        label: 'Total prestation',
        field: 'totalPerformanceQuantity',
        sortable: true,
      },
      {
        align: 'left',
        name: 'totalPerformanceUnit',
        label: 'Total prestations enhet',
        field: 'totalPerformanceUnit',
        sortable: true,
      },
    ]

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Fordonsskador',
        sheets: [
          {
            name: 'Fordonsskador',
            data: vehicleDefects.value.map((item) => {
              return columns?.reduce<{ [name: string]: unknown }>(
                (acc, col) => {
                  const value =
                    typeof col.field === 'function'
                      ? col.field(item)
                      : item[col.name as keyof VehicleDefect]

                  if (col.format) {
                    acc[col.label] = col.format(value, null)
                  } else {
                    acc[col.label] = value
                  }

                  return acc
                },
                {}
              )
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    return {
      columns,
      loading,
      vehicleDefects,
      exporting,
      can,
      onExport,
      filterText: ref(''),
    }
  },
})
